import React, { FunctionComponent } from "react";
import { Header } from "./header";
import { Footer } from "./footer";
import { Helmet } from "react-helmet";

import "./page.scss";
import { graphql, StaticQuery } from "gatsby";

interface Props {
  showHeader?: boolean;
  lightHeader?: boolean;
  transparentHeader?: boolean;
  menuId?: string;
  title?: string;
}

export const Page: FunctionComponent<Props> = (props) => {
  const { showHeader = true } = props;
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              menuLinks {
                name
                url
                id
              }
            }
          }
        }
      `}
      render={(data) => (
        <React.Fragment>
          <Helmet
            title={(props.title ? props.title + " - " : "") + data.site.siteMetadata.title}
            meta={[
              {
                name: "description",
                content:
                  "CNano: Advancing Nano Medicine with Next Generation Drug Delivery Technologies",
              },
              { name: "keywords", content: "CNano, Medicine, Drug, Nano" },
            ]}
            // link={[{ rel: "stylesheet", href: "https://use.typekit.net/wqm3xfw.css" }]}
          ></Helmet>
          <div style={{ display: "grid", gridTemplateRows: "auto 1fr auto", height: "100vh" }}>
            {showHeader ? (
              <Header
                light={props.lightHeader}
                transparent={props.transparentHeader}
                menu={data.site.siteMetadata.menuLinks}
                currentPageMenuId={props.menuId}
              />
            ) : null}
            <div>{props.children}</div>
            {/* <Footer /> */}
          </div>
        </React.Fragment>
      )}
    />
  );
};
