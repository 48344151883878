import React, { FunctionComponent } from "react";

import { Menu, MenuItem } from "./menu";

import * as css from "./header.module.scss";

interface Props {
  menu: MenuItem[];
  currentPageMenuId?: string;
  light: boolean;
  transparent: boolean;
}

export const Header: FunctionComponent<Props> = (props) => {
  return (
    <div
      className={`${css.header} ${props.light ? css.light : ""} ${
        props.transparent ? css.transparent : ""
      }`}
    >
      <div className={css.left}>
        <h1 className={css.logo}>
          <a href="/">CNANO</a>
        </h1>
      </div>
      <div className={css.right}>
        <Menu light={props.light} menu={props.menu} currentPageMenuId={props.currentPageMenuId} />
      </div>
    </div>
  );
};
