import React, { FunctionComponent, useCallback, useState } from "react";

import * as css from "./menu.module.scss";
import HamburgerMenuIcon from "../images/icon-hamburger-menu.svg";
import CloseIcon from "../images/icon-close.svg";

interface Props {
  class?: string;
  light: boolean;
  menu: MenuItem[];
  currentPageMenuId?: string;
}

export interface MenuItem {
  name: string;
  url: string;
  id: string;
}

export const Menu: FunctionComponent<Props> = (props) => {
  const [shown, setShown] = useState(false);

  const handleExpand = useCallback(() => {
    setShown(!shown);
  }, [shown]);

  return (
    <div className={css.menuContainer}>
      <button onClick={handleExpand} className={css.menuButton}>
        <HamburgerMenuIcon className={css.icon} />
      </button>
      <ul
        className={`${css.menu} ${shown ? css.overlay : ""} ${props.light ? css.light : ""} ${
          props.class ?? ""
        }`}
      >
        {props.menu.map((item, index) => (
          <li key={index}>
            <a className={props.currentPageMenuId === item.id ? css.selected : ""} href={item.url}>
              {item.name}
            </a>
          </li>
        ))}
        <li className={css.closeMenuItem}>
          <button onClick={handleExpand}>
            <CloseIcon className={css.icon} />
          </button>
        </li>
      </ul>
    </div>
  );
};
